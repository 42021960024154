@font-face {
  @include callrail-font-face();
}

@-moz-keyframes appear {
  0% {
    left: -5px;
    opacity: 0.5;
    width: 0px;
  }

  100% {
    left: 0px;
    opacity: 1;
    width: 20px;
  }
}

@-webkit-keyframes appear {
  0% {
    left: -5px;
    opacity: 0.5;
    width: 0px;
  }

  100% {
    left: 0px;
    opacity: 1;
    width: 20px;
  }
}

@keyframes appear {
  0% {
    left: -5px;
    opacity: 0.5;
    width: 0px;
  }

  100% {
    left: 0px;
    opacity: 1;
    width: 20px;
  }
}

.appear-icon::before {
  display: inline-block;
  position: relative;
  width: 20px;
  -webkit-animation: appear 0.2s ease forwards;
  -moz-animation: appear 0.2s ease forwards;
  -o-animation: appear 0.2s ease forwards;
  animation: appear 0.2s ease forwards;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

[class^='icon-'], [class*=' icon-'] {
  display: inline-block;
  font-family: 'callrail';
  font-size: $icon-font-size;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: text-bottom;
  position: relative;
  top: 1px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus, &:active {
    outline: 0 none;
  }

  &.pophelp {
    border: none;
  }

  &.spin-icon {
    -webkit-animation: spin 2s infinite linear;
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
  }

  &.rotate-clockwise {
    transform: rotate(90deg);
  }

  &.disabled {
    color: $gun-powder;
    cursor: not-allowed;
  }
}

@import 'icon-list';

@each $name, $char in $icons {
  .icon-#{$name}::before {
    content: $char;
  }
  /* stylelint-disable-next-line */
  @if $name == 'alert-error' {
    .icon-alert-warning::before, .icon-alert-danger::before {
      content: $char;
    }
  }
}

.platform-icon {
  display: inline-block;
  color: $white;
  border-radius: 50%;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 36px;

  &[class*='-small'] {
    height: 24px;
    line-height: 24px;
    width: 24px;
  }

  &[class*='billing-plans'] {
    height: 20px;
    line-height: 1;
    width: 20px;
    margin: 0px 5px;
  }

  &-cookie {
    height: 24px;
    line-height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: $attribluetion;
    color: $white;

    &[class*='-small']::before {
      display: block;
      text-align: center;
    }
  }

  &[class*='icon-call-tracking'] {
    background-color: $attribluetion;
  }

  &[class*='icon-conversation-intelligence'] {
    background-color: $vin-rouge;
  }

  &[class*='icon-convert-assist'] {
    background-color: $plum;
  }

  &[class*='icon-form-tracking'] {
    background-color: $sunflower;
  }

  &[class*='icon-lead-center'] {
    background-color: $ringo-mint;
  }

  &.platform-icon--inactive {
    background-color: $dark-solitude;
    color: $silver-chalice;
  }
}

.round-icon {
  display: inline-block;
  color: $steel-blue;
  border: 1px solid $steel-blue;
  border-radius: 50%;
  height: 36px;
  line-height: 46px;
  text-align: center;
  width: 36px;

  i {
    top: -5px;
  }

  &.round-icon--disabled {
    color: $gun-powder;
    border-color: $gun-powder;
  }
}

.layered-round-icons {
  background: inherit;

  .round-icon {
    background: inherit;
    margin-left: -10px;

    &:nth-child(1) {
      margin-left: 0px;
      z-index: 1;
    }
  }
}

.normalize-check-filled {
  line-height: 20px;
  font-size: 29px;
}

.white-label .round-icon {
  color: $mine-shaft;
  border: 1px solid $mine-shaft;
}

.collapsed-icon {
  @include transform(rotate(180deg));
}
