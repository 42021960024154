.u-margin-left-5 {
  margin-left: 5px !important;
}

.u-margin-left-10 {
  margin-left: 10px !important;
}

.u-margin-left-15 {
  margin-left: 15px !important;
}

.u-margin-left-20 {
  margin-left: 20px !important;
}

.u-margin-left-24 {
  margin-left: 24px !important;
}

.u-margin-right-0 {
  margin-right: 0 !important;
}

.u-margin-right-5 {
  margin-right: 5px !important;
}

.u-margin-right-10 {
  margin-right: 10px !important;
}

.u-margin-right-15 {
  margin-right: 15px !important;
}

.u-margin-right-20 {
  margin-right: 20px !important;
}

.u-margin-right-40 {
  margin-right: 40px !important;
}

.u-margin-top-0 {
  margin-top: 0 !important;
}

.u-margin-top-5 {
  margin-top: 5px !important;
}

.u-margin-top-10 {
  margin-top: 10px !important;
}

.u-margin-top-15 {
  margin-top: 15px !important;
}

.u-margin-top-20 {
  margin-top: 20px !important;
}

.u-margin-top-24 {
  margin-top: 24px !important;
}

.u-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.u-margin-bottom-5 {
  margin-bottom: 5px !important;
}

.u-margin-bottom-10 {
  margin-bottom: 10px !important;
}

.u-margin-bottom-15 {
  margin-bottom: 15px !important;
}

.u-margin-bottom-20 {
  margin-bottom: 20px !important;
}

.u-margin-bottom-24 {
  margin-bottom: 24px !important;
}

.u-margin-bottom-30 {
  margin-bottom: 30px !important;
}

.u-min-width-100 {
  min-width: 100px;
}

.u-padding-24 {
  padding: 24px;
}

.u-padding-top-0 {
  padding-top: 0 !important;
}

.u-padding-top-24 {
  padding-top: 24px !important;
}

.u-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.u-padding-bottom-5 {
  padding-bottom: 5px !important;
}

.u-padding-bottom-15 {
  padding-bottom: 15px !important;
}

.u-padding-bottom-20 {
  padding-bottom: 20px !important;
}

.u-padding-bottom-30 {
  padding-bottom: 30px !important;
}

.u-padding-left-0 {
  padding-left: 0 !important;
}

.u-padding-left-5 {
  padding-left: 5px !important;
}

.u-padding-left-10 {
  padding-left: 10px !important;
}

.u-padding-right-0 {
  padding-right: 0 !important;
}

.u-padding-right-5 {
  padding-right: 5px !important;
}

.u-padding-x-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.u-padding-y-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.u-text-align-center {
  text-align: center;
}

.u-text-align-left {
  text-align: left;
}

.u-text-align-right {
  text-align: right;
}

.u-muted {
  color: $silver-chalice !important;
}

.u-muted-link, .u-muted a {
  color: $silver-chalice !important;
  text-decoration: underline;

  &i, i {
    text-decoration: none;
  }

  &:hover {
    color: $gun-powder !important;
  }

  &:active {
    color: $mine-shaft !important;
  }

  &:focus {
    color: $gun-powder !important;
    outline-color: $gun-powder !important;
  }
}

.u-break-word {
  word-break: break-word;
}

.u-break-all {
  word-break: break-all;
}

.u-no-margin, .u-margin-0 {
  margin: 0 !important;
}

.u-no-padding {
  padding: 0 !important;
}

.u-max-width-none {
  max-width: none !important;
}

.u-default-margin-bottom {
  margin-bottom: $default-margin-bottom !important;
}

.u-no-margin-after {
  margin-bottom: 0;
}

.u-clearfix {
  @include clearfix;
}

.u-hide {
  display: none;
}

.u-visibility-visible {
  visibility: visible !important;
}

.u-visibility-hidden {
  visibility: hidden !important;
}

.u-block {
  display: block !important;
}

.u-inline-block {
  display: inline-block !important;
}

.u-flex {
  @include flex;

  &.u-flex-space-between {
    justify-content: space-between;
  }

  &.u-flex-justify-right {
    justify-content: right;
  }

  &.u-flex-justify-center {
    justify-content: center;
  }

  &.u-align-items-center {
    align-items: center;
  }

  &.u-align-items-baseline {
    align-items: baseline;
  }

  &.u-align-items-flex-end {
    align-items: flex-end;
  }

  &.u-flex-wrap {
    flex-wrap: wrap;
  }
}

.u-contain-flex-elements {
  min-width: 0px;
}

.u-flex-1 {
  flex: 1;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-order-0 {
  order: 0;
}

.u-order-1 {
  order: 1;
}

.u-inline-flex {
  @include inline-flex;
}

.u-align-items-center {
  @include inline-flex;
  align-items: center;
}

.u-align-items-flex-start {
  @include inline-flex;
  align-items: flex-start;
}

.u-align-self-end {
  align-self: flex-end;
}

.u-inline {
  display: inline !important;
}

.u-vertical-align-baseline {
  vertical-align: baseline;
}

.u-vertical-align-middle {
  vertical-align: middle;
}

.u-vertical-align-top {
  vertical-align: top;
}

.u-vertical-align-bottom {
  vertical-align: bottom;
}

.u-vertical-align-sub {
  vertical-align: sub;
}

.u-default-line-height {
  line-height: $default-line-height !important;
}

.u-float-none {
  float: none;
}

.u-pull-left, .u-float-left {
  float: left;
}

.u-pull-right {
  float: right;
}

.u-contain {
  position: relative;
}

.u-position-fixed {
  position: fixed;
}

.u-position-sticky {
  position: sticky;
}

.u-no-text-decoration {
  text-decoration: none !important;
}

.u-link-disabled {
  color: unset;
}

.u-link-disabled, .u-pointer-events-none {
  pointer-events: none;
}

.u-item-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.u-border-top {
  border-top: $default-border;
}

.u-border-right {
  border-right: $default-border;
}

.u-border-bottom {
  border-bottom: $default-border;
}

.u-border-left {
  border-left: $default-border;
}

.u-no-border {
  border: none !important;
}

/* Absolute positioning
------------------------------------------------------- */

.u-pin-top,
.u-pin-right,
.u-pin-bottom,
.u-pin-left,
.u-pin-topleft,
.u-pin-topright,
.u-pin-bottomleft,
.u-pin-bottomright {
  position: absolute;
}

.u-pin-all {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.u-pin-bottom {
  right: 0;
  bottom: 0;
  left: 0;
}

.u-pin-top {
  top: 0;
  right: 0;
  left: 0;
}

.u-pin-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.u-pin-right {
  top: 0;
  right: 0;
  bottom: 0;
}

.u-pin-bottomright {
  bottom: 0;
  right: 0;
}

.u-pin-bottomleft {
  bottom: 0;
  left: 0;
}

.u-pin-topright {
  top: 0;
  right: 0;
}

.u-pin-topleft {
  top: 0;
  left: 0;
}

.u-whitespace-no-wrap {
  white-space: nowrap !important;
}

.u-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-scroll-y {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-x-hidden {
  overflow-x: hidden;
}

.u-overflow-x-auto {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.u-overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.u-center-horizontally {
  @include u-center-horizontally
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-default {
  cursor: default;
}

.u-round {
  border-radius: 50%;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-text-transform-initial {
  text-transform: initial;
}

.u-default-font-size {
  font-size: $default-font-size !important;
}

.u-normal-font-weight {
  font-weight: $default-normal-weight !important;
}

.u-font-weight-semibold {
  font-weight: $default-semibold-weight;
}

.u-z-depth-1 {
  @include z-depth(1);
}

.u-z-depth-2 {
  @include z-depth(2);
}

.u-z-depth-3 {
  @include z-depth(3);
}

.u-wrap-whitespace {
  white-space: pre-wrap;
}

.u-strikethrough {
  color: $cosmos;
  text-decoration: line-through;
  font-weight: 600;
}

.u-text-decoration-line-through {
  text-decoration: line-through;
}

.u-full-height {
  height: 100%;
}

.u-full-width {
  width: 100%;
}

.u-auto-width {
  width: auto !important;
}

.u-highlight {
  background-color: $creamed-corn !important;
}

.u-highlight-table-cell {
  display: inherit;
  border-bottom: 5px solid $creamed-corn;
  padding-bottom: 7px;
}

div.btn-light.bg-primary.text-white {
  background-color: $attribluetion;
  color: $white;
  border: 0;
}

.u-default-transition {
  transition: $default-transition-ease;
}

@for $i from 1 through 3 {
  .u-flex-grow-#{$i} {
    flex-grow: #{$i} !important;
    flex-basis: 0 !important;
  }
}

@each $color-name in $color-set-utility {
  $title: color-title($color-name);
  // color utility classes
  .u-color-#{$title} {
    color: background($color-name) !important;
  }

  // background-color utility classes
  .u-background-color-#{$title} {
    background-color: background($color-name) !important;
  }
}

@media (max-width: $screen-size-small) {
  .u-hide-small {
    display: none;
  }

  .u-show-small {
    display: block;
  }

  .u-margin-small-20 {
    margin: 20px;
  }
}

@media (max-width: 1280px) {
  .u-margin-b-20-xl {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .u-hide-large {
    display: none;
  }
}

@media (max-width: 1023px) {
  .u-show-large {
    display: block
  }
}

@media (max-width: $screen-size-medium) {
  .u-hide-medium {
    display: none;
  }
}

@media (max-width: ($screen-size-medium - 1)) {
  .u-show-medium {
    display: block
  }
}

// Tailwind
.u-responsive-grid-layout {
  @apply tw-grid tw-grid-cols-12 tw-gap-x-5;
}

.u-thin-scrollbar {
  scrollbar-color: rgba(0, 0, 0, .2) transparent;
  scrollbar-width: thin;
}
