// stylelint-disable color-no-hex
$white--persistent: #ffffff;
$white: var(--color-white);
$black--persistent: #000000;
$black: var(--color-black);

/****************
  Grays
*****************/

// white label friendly colors for
// borders and icons
$dark-solitude--persistent: #eaedf1;
$dark-solitude: var(--color-dark-solitude);

$silver--persistent: #bac1c8;
$silver: var(--color-silver);

$silver-chalice--persistent: #9ba3ad;
$silver-chalice: var(--color-silver-chalice);

$gun-powder--persistent: #4c545d;
$gun-powder: var(--color-gun-powder);

// default font color
$mine-shaft--persistent: #272b2f;
$mine-shaft: var(--color-mine-shaft);

/****************
  Blues
*****************/

// extended light gray background
// edit table row background
// active vertical tab
// table row hover background
// dropdown selected item background
$solitude--persistent: #f7f9fa;
$solitude: var(--color-solitude);

// callrail blue accents used for borders
// and backgrounds
$light-steel-blue--persistent: #e2ebf5;
$light-steel-blue: var(--color-light-steel-blue);

$nepal--persistent: #accdf2;
$nepal: var(--color-nepal);

// primary button (call to action)
// feature list
$steel-blue--persistent: #6babf3;
$steel-blue: var(--color-steel-blue);

// link color
// active horizontal tab bottom border
// primary nav active tab top border
// empty table
// icon color in tables
$attribluetion--persistent: #126cd3;
$attribluetion: var(--color-attribluetion);

$smugglers-cove--persistent: #1755a0;
$smugglers-cove: var(--color-smugglers-cove);

/****************
  Supporting Palette
*****************/

// caboose
$caboose-purple: #7B61FF;

// superadmin colors
$superadmin: #3c8787;
$ringo-mint: #50d3be;

// pending state
$sunflower: #ffdf5e;

// success state
$medium-green: #30b967;

// error state
$valencia: #d73330;

// voicemail icon / badge color
$pumpkin-spice-latte: #ee9151;

// kanban header color
$nail-salon: #de3e8e;

// kanban header color
$buffalo-sauce: #f0543a;

// kanban header color
$caribbean: #03bbde;

$plum: #643af0;
$amazonite: #0ab7b5;
$vin-rouge: #a2547e;
$lavender: #4C3D76;

/****************
  Chart Colors
*****************/

$report-01: #4a7ab3;
$report-02: #86baee;
$report-03: #7dc789;
$report-04: $vin-rouge;
$report-05: #e76c58;
$report-06: $amazonite;
$report-07: #59caef;
$report-08: #ffd166;
$report-09: #123c7c;
$report-10: #ddabb1;
$report-11: #bac1c8;
$report-12: #6d94c3;
$report-13: #a6ebc0;
$report-14: #da4759;
$report-other: #da4759;

/****************
  Platform Colors
*****************/

$calls-color: $attribluetion;
$forms-color: $sunflower;
$ci-color: $vin-rouge;
$ca-color: $plum;
$lead-center-color: $ringo-mint;
$lead-with-lead-center-color: $ringo-mint;

/****************
  Alerts
*****************/

$cornflower: #c7e6fd;
$forest-green: #246b3e;
$frosted-mint: #a6ebc0;
$burnt-umber: #915004;
$creamed-corn: #fff3c2;
$brick-red: #b81f1f;
$cosmos: #fcd6d6;

/****************
  Generics and legacy
  DO NOT ADD TO THIS LIST
  If you think you need one of these colors, talk to a designer first
*****************/
$generic-ccc: #cccccc;
$generic-999: #999999;
$generic-777: #777777;
$generic-efef: #efefef;
$generic-444: #444444;
$generic-4646: #464646;
$generic-8080: #808080;
$generic-d8d8: #d8d8d8;
$generic-c6c6: #c6c6c6;
$generic-b3b3: #b3b3b3;
$generic-7c7c: #7c7c7c;
$generic-dce6ec: #dce6ec;
$generic-e8e8: #e8e8e8;
$generic-f8f8: #f8f8f8;
$generic-beccd4: #beccd4;
$generic-cbd9e2: #cbd9e2;
$generic-b1b1: #b1b1b1;
$generic-8585: #858585;
$generic-888: #888888;
$generic-9090: #909090;

$legacy-blue: #5281a0;
$legacy-light-blue: #8fadc1;
$legacy-white-blue: #e6ecf0;
$legacy-dark-blue: #23688d;

/****************
  Used only for replacing browser outlines.
*****************/
$chrome-outline-blue: #52a8ec;

/****************
  Color Schemes:
  Given a named color, what colors work well with it?
*****************/

$color-schemes: (
  // grays:
  'white': ('background':  $white, 'foreground': $mine-shaft),
  'solitude': ('background':  $solitude, 'foreground': $mine-shaft),
  'dark-solitude': ('background':  $dark-solitude, 'foreground': $mine-shaft),
  'silver': ('background':  $silver, 'foreground': $mine-shaft),
  'silver-chalice': ('background':  $silver-chalice, 'foreground': $mine-shaft),
  'gun-powder': ('background':  $gun-powder, 'foreground': $white),
  'mine-shaft': ('background':  $mine-shaft, 'foreground': $white),
  'black': ('background':  $black, 'foreground': $white),

  // blues:
  'light-steel-blue': ('background':  $light-steel-blue, 'foreground': $mine-shaft),
  'nepal': ('background':  $nepal, 'foreground': $mine-shaft),
  'steel-blue': ('background': $steel-blue, 'foreground': $white),
  'attribluetion': ('background': $attribluetion, 'foreground': $white),
  'smugglers-cove': ('background': $smugglers-cove, 'foreground': $white),

  'light-steel-blue-persistent': ('title': 'light-steel-blue', 'background':  $light-steel-blue--persistent, 'foreground': $mine-shaft),
  'nepal-persistent': ('title': 'nepal', 'background':  $nepal--persistent, 'foreground': $mine-shaft),
  'steel-blue-persistent': ('title': 'steel-blue', 'background': $steel-blue--persistent, 'foreground': $white),
  'attribluetion-persistent': ('title': 'attribluetion', 'background': $attribluetion--persistent, 'foreground': $white),
  'smugglers-cove-persistent': ('title': 'smugglers-cove', 'background': $smugglers-cove--persistent, 'foreground': $white),

  // supporting palette:
  'superadmin': ('background': $superadmin, 'foreground': $white),
  'ringo-mint': ('background': $ringo-mint, 'foreground': $mine-shaft),
  'buffalo-sauce': ('background': $buffalo-sauce, 'foreground': $white),
  'caribbean': ('background': $caribbean, 'foreground': $mine-shaft),
  'nail-salon': ('background': $nail-salon, 'foreground': $white),
  'plum':  ('background': $plum, 'foreground': $white, 'hover': #340DB8),
  'amazonite':  ('background': $amazonite, 'foreground': $white),
  'vin-rouge':  ('background': $vin-rouge, 'foreground': $white),
  'caboose-purple':  ('background': $caboose-purple, 'foreground': $white),
  'lavender':  ('background': $lavender, 'foreground': $white, 'hover': #211A32),

  // status:
  'medium-green': ('background': $medium-green, 'foreground': $white),
  'sunflower': ('background': $sunflower, 'foreground': $mine-shaft),
  'pumpkin-spice-latte': ('background': $pumpkin-spice-latte, 'foreground': $mine-shaft),
  'valencia': ('background': $valencia, 'foreground': $white),

  // alerts:
  'info':    ('background': $cornflower, 'foreground': $smugglers-cove--persistent),
  'warning': ('background': $creamed-corn, 'foreground': $burnt-umber),
  'danger':  ('background': $cosmos, 'foreground': $brick-red),
  'success': ('background': $frosted-mint, 'foreground': $forest-green),
  'standout': ('background': $white, 'foreground': $attribluetion--persistent),
  'warning-light': ('background': $white, 'foreground': $black),

  'creamed-corn': ('background': $creamed-corn, 'foreground': $mine-shaft),
  'cosmos':  ('background': $cosmos, 'foreground': $mine-shaft),
  'frosted-mint': ('background': $frosted-mint, 'foreground': $mine-shaft),
  'cornflower':    ('background': $cornflower, 'foreground': $mine-shaft),
  'burnt-umber': ('background': $burnt-umber, 'foreground': $white),
  'brick-red':  ('background': $brick-red, 'foreground': $white),
  'forest-green': ('background': $forest-green, 'foreground': $white),

  // reports:
  'report-01': ('background': $report-01, 'foreground': $white),
  'report-02': ('background': $report-02, 'foreground': $white),
  'report-03': ('background': $report-03, 'foreground': $white),
  'report-04': ('background': $report-04, 'foreground': $white),
  'report-05': ('background': $report-05, 'foreground': $white),
  'report-06': ('background': $report-06, 'foreground': $white),
  'report-07': ('background': $report-07, 'foreground': $white),
  'report-08': ('background': $report-08, 'foreground': $mine-shaft),
  'report-09': ('background': $report-09, 'foreground': $white),
  'report-10': ('background': $report-10, 'foreground': $white),
  'report-11': ('background': $report-11, 'foreground': $white),
  'report-12': ('background': $report-12, 'foreground': $white),
  'report-13': ('background': $report-13, 'foreground': $white),
  'report-14': ('background': $report-14, 'foreground': $white),
  'report-other': ('background': $report-other, 'foreground': $white),

  // customer tags:
  'gray1': ('background':  #e7e7e7, 'foreground': #464646),
  'gray2': ('background':  #c2c2c2, 'foreground': $mine-shaft),
  'gray3': ('background':  #4c545d, 'foreground': $white),
  'blue1': ('background':  #b5cef7, 'foreground': #0f3778),
  'blue2': ('background':  #4583ea, 'foreground': $white),
  'cyan1': ('background':  #96d7e5, 'foreground': #0d404a),
  'cyan2': ('background':  #23a2bd, 'foreground': $white),
  'purple1': ('background':  #e3d6ff, 'foreground': #3d0d90),
  'purple2': ('background':  #b997ff, 'foreground': $white),
  'pink1': ('background':  #fcd3e0, 'foreground': #761e3a),
  'pink2': ('background':  #f890b2, 'foreground': #9a4964),
  'pink3': ('background':  #ebdbde, 'foreground': #6b323b),
  'pink4': ('background':  #cda6ac, 'foreground': $white),
  'red1': ('background': #f3b2a7, 'foreground': #8c1b00),
  'red2': ('background': #fe4a22, 'foreground': $white),
  'orange1': ('background':  #ffc8ad, 'foreground': #7f3306),
  'orange2': ('background':  #ff7529, 'foreground': $white),
  'orange3': ('background':  #ffdfb2, 'foreground': #7d4900),
  'orange4': ('background':  #ffae38, 'foreground': $white),
  'yellow1': ('background':  #fcea7c, 'foreground': #594d00),
  'yellow2': ('background':  #fdeebf, 'foreground': #694f00),
  'green1': ('background':  #b1f0d2, 'foreground': #054f2f),
  'green2': ('background':  #38d790, 'foreground': #044227),
  'green3': ('background':  #a0ddc0, 'foreground': #015531),
  'green4': ('background':  #00a863, 'foreground': $white)
);

/****************
  Color Sets:
  Collections of color schemes that can be iterated through
*****************/

$color-set-blue: (
  'light-steel-blue-persistent',
  'nepal-persistent',
  'steel-blue-persistent',
  'attribluetion-persistent',
  'smugglers-cove-persistent'
);

$color-set-gray: (
  'white',
  'solitude',
  'dark-solitude',
  'silver',
  'silver-chalice',
  'gun-powder',
  'mine-shaft',
  'black'
);

$color-set-supporting: (
  'superadmin',
  'ringo-mint',
  'nail-salon',
  'buffalo-sauce',
  'caribbean',
  'plum',
  'amazonite',
  'vin-rouge',
  'caboose-purple',
  'lavender',
);

$color-set-status: (
  'medium-green',
  'sunflower',
  'pumpkin-spice-latte',
  'valencia'
);

$color-set-alerts: (
  'info',
  'warning',
  'danger',
  'success',
  'warning-light',
  'standout'
);

$color-set-alerts-base: (
  'creamed-corn',
  'cosmos',
  'frosted-mint',
  'cornflower',
  'burnt-umber',
  'brick-red',
  'forest-green'
);

$color-set-reports: (
  'report-01', 'report-02', 'report-03', 'report-04', 'report-05', 'report-06',
  'report-07', 'report-08', 'report-09', 'report-10', 'report-11', 'report-12', 'report-13', 'report-14', 'report-other'
);

$color-set-customer-tags: (
  'gray1', 'gray2', 'gray3',
  'blue1', 'blue2',
  'cyan1', 'cyan2',
  'purple1', 'purple2',
  'pink1', 'pink2', 'pink3', 'pink4',
  'red1', 'red2',
  'orange1', 'orange2', 'orange3', 'orange4',
  'yellow1', 'yellow2',
  'green1', 'green2', 'green3', 'green4'
);

$color-set-platforms: (
  'calls-color', 'forms-color', 'ci-color',  'lead-center-color', 'lead-with-lead-center-color'
);

// Color Sets are just collections of color names, so they
// can be built from OTHER color sets:

$color-set-utility: multi(
  $color-set-gray,
  $color-set-blue,
  $color-set-supporting,
  $color-set-reports,
  $color-set-status,
  $color-set-alerts-base
);

$color-set-avatars: multi(
  $color-set-supporting,
  $color-set-reports,
  $color-set-status
);

$color-set-tags: multi(
  $color-set-customer-tags,
  $color-set-status,
  $color-set-supporting,
  ('silver-chalice', 'gun-powder', 'mine-shaft', 'nepal-persistent', 'attribluetion-persistent')
);
